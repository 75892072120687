/*jshint esversion: 6 */
document.addEventListener("DOMContentLoaded", function (event) {
    // Back to top button
    let gotoTopBtn = document.getElementById("btn-back-to-top");
    gotoTopBtn.addEventListener("click", backToTop);
    window.onscroll = function () {
        scrollFunction();
    };

    function scrollFunction() {
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            // gotoTopBtn.style.display = "block";
            gotoTopBtn.classList.add("d-block");
            gotoTopBtn.classList.remove("d-none");
        } else {
            // gotoTopBtn.style.display = "none";
            gotoTopBtn.classList.remove("d-block");
            gotoTopBtn.classList.add("d-none");
        }
    }

    function backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
});
